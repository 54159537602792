<template>
  <div id="inspire">
    <v-navigation-drawer v-model="drawer" app dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Acordo Online </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            :class="item.class"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list dense nav class="logoutStyle">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in itemsLogout"
            :key="item.title"
            :to="item.to"
            :class="item.class"
            @click="logout"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#1457ed" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <div class="content">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "LayoutAdmin",
  data() {
    return {
      drawer: null,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/admin-dashboard",
        },
        { title: "Filtro", icon: "mdi-filter-outline", to: "/admin-filtro" },
      ],
      itemsLogout: [
        { title: "Logout", icon: "mdi-logout", class: "logoutStyle" },
      ],
      right: null,
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped>
.logoutStyle {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>